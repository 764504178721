import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
// import ContactInfoWidget from '../Widget/ContactInfoWidget';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import arrowPic from './public/ima ges/demo/main-home.jpeg'
export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formdata, setFormData] = useState({

  });
  const handlechange = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value
    })
  }
  const [contactAlert, setContactAlert] = useState(false)
  const handlesubmit = async (event) => {
    event.preventDefault();
    console.log(formdata);
    const data = {
      email: formdata.email,
      fullName: formdata.name,
      phoneNumber: formdata.mobile,
      projectType: formdata.projectType,
      message: formdata.message
    }
    try {
      const response = await axios.post(
        `https://api.devop360.com/api/user/feedback`,
        {
          email: formdata.email,
          fullName: formdata.name,
          phoneNumber: formdata.mobile,
          projectType: formdata.projectType,
          message: formdata.message
        },
      );
      console.log('verifation responce ', response.data);
      // Handle response from server
      if (
        response.data.message ===
        'Thanks for contacting us'
      ) {

        // alert('Thank you for contacting');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          projectType: '',
          message: ''
        });
        setContactAlert(true);
        setTimeout(() => setContactAlert(false), 5000);
      }
    } catch (error) {
      console.error(error);
      alert('Failed to verify OTP');
    }
  }
  return (
    <>
      <Div>
        <Div
          className="cs-page_heading cs-style1 cs-center text-center cs-bg"
          style={{ backgroundImage: `url(images/service-hero.jpg)` }}
        ></Div>

        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row" style={{marginTop:'-32%' ,position:'relative'}}>
            <Div className="col-lg-6">
              <SectionHeading
                // contactBtn={''}
                title="Ready to talk about your project?"
                subtitle=""
              />
              <Spacing lg="55" md="30" />
              {/* <ContactInfoWidget withIcon /> */}
              <Spacing lg="0" md="50" />
              <Div className='contact-para'><p>We're happy to answer any question you may have and help you determine which of our services best fits your needs.</p></Div>
              <Spacing lg='50' md='50' />
              <Div className='sub-header'><h2>Call us at: <Link to="https://wa.me/+923317537497" target='_blank'>+92 331 7537497</Link></h2></Div>
              <Div className='contact-benefits'>
                <h2>Your benefits:</h2>
                <Div className='benefits-items col-lg-8'>
                  <Div className='item col-md-6'>  <span><Icon icon="akar-icons:check"></Icon></span>Client-oriented</Div>
                  <Div className='item col-md-6'>  <span><Icon icon="akar-icons:check"></Icon></span>Results-driven</Div>
                  <Div className='item col-md-6'>  <span><Icon icon="akar-icons:check"></Icon></span>Independent</Div>
                  <Div className='item col-md-6'>  <span><Icon icon="akar-icons:check"></Icon></span>Problem-solving</Div>
                  <Div className='item col-md-6'>  <span><Icon icon="akar-icons:check"></Icon></span>Transparent</Div>
                  <Div className='item col-md-6'>  <span><Icon icon="akar-icons:check"></Icon></span>Fast Delivery</Div>
                </Div>
              </Div>
              <Spacing lg='50' />
              <Div className='contact-next'>
                <h2>What happens next?</h2>
                <Div className='next-menu'>
                  <Div className='next-item col-md-5' >
                    <span>1</span>
                    <p>We Schedule a call at your convenience </p>
                  </Div>
                  <Div className='next-item col-md-5'>
                    <span>2</span>
                    <p>We do a discovery and consulting meting</p>
                  </Div>
                  <Div className='next-item col-md-5'>
                    <span>3</span>
                    <p>We prapare a proposal</p>
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div className="col-lg-6">
              <Div className='form-wrapper'>
                <Div className='form-header'>
                  <h1>Schedule a Free Consulation</h1>
                </Div>
                <Div>
                <hr className='form-divider'/>
                {/* <img src={arrowPic} alt="" /> */}
                </Div>

                <form action="#" className="row contact-form" onSubmit={handlesubmit} >
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">First Name</label>
                    <input type="text" className="cs-form_field" name='name' value={formdata.name} onChange={(e) => { handlechange(e) }} required/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-6">
                    <label className="cs-primary_color">Last Name</label>
                    <input type="text" className="cs-form_field" name='name' value={formdata.name} onChange={(e) => { handlechange(e) }} required/>
                    <Spacing lg="20" md="20" />
                  </Div>
                 
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Company/Organization</label>
                    <input type="text" className="cs-form_field" name='projectType' value={formdata.projectType} onChange={(e) => { handlechange(e) }} required/>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Company Email</label>
                    <input type="text" className="cs-form_field" name='email' value={formdata.email} onChange={(e) => { handlechange(e) }} />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Phone</label>
                    <input type="number" className="cs-form_field" name='mobile' value={formdata.mobile} onChange={(e) => { handlechange(e) }} />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div  className="col-sm-12">
                    <label  className="cs-primary_color">How can We Help You?</label>
                    <select name="" id=""  className="cs-form_field">
                      <option value="" selected>Select option</option>
                      <option value="">option2</option>
                      <option value="">option3</option>
                      <option value="">option4</option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-sm-12">
                    <label className="cs-primary_color">Message</label>
                    <textarea
                      cols="30"
                      rows="7"
                      className="cs-form_field"
                      name='message'
                      value={formdata.message}
                      onChange={(e) => { handlechange(e) }}
                    ></textarea>
                    <Spacing lg="25" md="25" />
                  </Div>
                  <Div className="col-sm-12">
                    <button className="cs-btn cs-style1">
                      <span>Send Message</span>
                      <Icon icon="bi:arrow-right" />
                    </button>
                  </Div>

                </form>
              </Div>


            </Div>

            {
              contactAlert &&
              <div className="mt-4 alert alert-success alert-dismissible fade show" role="alert">
                <strong>Thank You! for contacting us.</strong>
                <button className="btn-close" onClick={() => setContactAlert(false)}></button>
              </div>}
          </Div>
        </Div>
      
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </Div>
    </>
  );
}
