import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './portfolio.scss';

export default function Portfolio({ href, src, title, subtitle, variant ,position}) {
  const ref = useRef();
  const [overlay , setOverlay] = useState('overlay-closed')
  const triggerOverlay = ()=>{
    // ref.current.style.visibility = "visible";
    // ref.current.style.opacity = "1";
    setOverlay('overlay-open');
  };
  const closeoverlay = ()=>{
    // console.log( this.classList());
    // ref.current.style.visibility = "hidden !important";
    // ref.current.style.opacity = "0";
    setOverlay('overlay-closed');
  };
  return (
    <Div>
      {/* // to=""
      // to={href} */}
      <Div
      className={`cs-portfolio cs-bg ${variant ? variant : 'cs-style1'}`}
      style={{borderRadius: '22px',
      backgroundImage: `url("${src}")`,
      backgroundSize: "cover",
      backgroundPosition: "0 0",
      transition: "background-position 2s"          
    }}
      onClick={triggerOverlay}
      />
      <>
        {/* <Div className="cs-portfolio_hover" /> */}
        {/* <Div
          className="cs-portfolio_bg cs-bg"
          style={{ backgroundImage: `url("${src}")` }}
        /> */}
        {/* <img src={src} alt="" /> */}
        <Div className={`cs-portfolio-overlay-image ${overlay}`} ref={ref} >
          <Div className="close-btn" onClick={closeoverlay}>X</Div>
          <Div className='cs-portfolio-image'>
            <img src={src} />
          </Div>
        </Div>
        {/* <Div className="cs-portfolio_info" >
          <Div className="cs-portfolio_info_bg cs-accent_bg" />
          <h2 className="cs-portfolio_title" ref={ref}>{title}</h2>
          <Div className="cs-portfolio_subtitle">{subtitle}</Div>
        </Div> */}
      </>
    </Div>
  );
}
