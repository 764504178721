import React from 'react'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <Link to="https://wa.me/+923317537497" target='_blank'>+92 331 7537497</Link>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <Link to="mailto:info@devop360.com" target='_blank'>info@devop360.com</Link>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          A40 Elm road, Feltham Middx <br/>TW14 8EW United Kingdom.
        </li>
      </ul>
    </>
  )
}
