import React, { useState, useEffect } from 'react'
import emailjs from 'emailjs-com'
import Div from '../Div'

export default function Newsletter({title, subtitle, placeholder}) {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    let timer
    if (status) {
      timer = setTimeout(() => {
        setStatus('')
      }, 5000)
    }
    return () => clearTimeout(timer)
  }, [status])

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      // Replace these with your EmailJS credentials
      const templateParams = {
        to_email: email,
        message: 'Thank you for subscribing to our newsletter!'
      }
      
      await emailjs.send(
        'YOUR_SERVICE_ID',
        'YOUR_TEMPLATE_ID',
        templateParams,
        'YOUR_USER_ID'
      )
      
      setStatus('Thank you for subscribing!')
      setEmail('')
    } catch (error) {
      setStatus('Failed to subscribe. Please try again.')
      console.error('Error:', error)
    }
    setEmail('');
  }

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form onSubmit={handleSubmit} className="cs-newsletter_form">
          <input 
            type="email" 
            className="cs-newsletter_input" 
            placeholder={placeholder}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required 
          />
          <button type="submit" className="cs-newsletter_btn">
            <span>Send</span>
          </button>
        </form>
        {status && <Div className="cs-newsletter_text" style={{color: status.includes('Failed') ? 'red' : 'green'}}>{status}</Div>}
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}
